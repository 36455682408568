@import '../../styles/mixins.scss';

.Dopplr {
    &__PageNotFound {
        color: white;
    }

    &__SkyboxWrapperSandbox {
        position: absolute;
        z-index: 2;
        padding-bottom: 3em;
        left: 0;
        right: 0;
        background: white;
        padding: 0.5em;
        bottom: -5.5em;
        box-shadow: 0 -2px 2px 0 rgb(0, 0, 0, 0.25);
        transition: bottom 0.2s ease-in;
        -webkit-transition: bottom 0.2s ease-in;
        -moz-transition: bottom 0.2s ease-in;

        &.show {
            bottom: 0em;
        }

        &__ToggleButton {
            background: white;
            width: 2em;
            height: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            box-shadow: 0 4px 10px rgb(0, 0, 0, 0.25);
            z-index: 1;
            cursor: pointer;

            img {
                width: 60%;
                transform: rotate(0deg);

                &.show {
                    transform: rotate(180deg);
                }

                transition: transform 0.2s ease-in;
            }
        }

        &__List {
            display: flex;
            align-items: center;
            // box-shadow: 0 -2px 2px 0 rgb(0, 0, 0, 0.25);
            overflow-x: scroll;
            @include hideScrollBar;

            &__Skybox {
                &__Item {
                    height: 3em;
                    border-radius: 0.5em;
                    overflow: hidden;
                    margin-right: 0.5em;
                    cursor: pointer;
                    box-sizing: border-box;
                    border: 1px solid #5c5c5c;

                    &.selected {
                        border: 1px solid black;
                    }

                    &.overlay {
                        opacity: 0.6;
                    }

                    img {
                        height: 100%;
                        width: 96px;
                    }
                }
            }
        }
    }

    &__AssetViewer {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        display: flex;

        &__Loader {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &__Left {
            flex: 1;
        }

        &__ProductImage {
            height: 100%;
            margin: 1em;

            img {
                max-height: 300px;
                height: 100%;
                object-fit: contain;
            }
        }

        &__Selections {
            display: flex;
            align-items: center;
            // position: absolute;
            // top: 0;
            // right: 0;
            // left: 0;
            background: #fff;
        }

        &__Container {
            display: flex;
            flex: 1;
        }

        &__AutoComplete {
            margin: 1em;
        }

        &__Scene {
            // min-width: 450px;
            // width: 33%;
            // min-height: 600px;
            text-align: center;
            border-left: 1px solid;
            width: 100%;
        }

        &__Label {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
        }
    }
}