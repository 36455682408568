.Dopplr_PermissionInfo {
    position: absolute;
    z-index: 1500;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__Container {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__Text {
        margin: 0.5em 0;
        text-align: center;
    }

    h1 {
        margin: 0;
    }
}