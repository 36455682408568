@import '../../styles/mixins.scss';

$color-1: #00FF77;
$color-2: #FF0000;

.allow-frame-tf {
  z-index: 4;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 6px solid $color-1;

  &--warning {
    border: 6px solid $color-2;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 4;

    .allow-frame-tf__warning-content {
      visibility: visible;
      opacity: 1;
    }
  }

  &--hidden {
    opacity: 0;
  }

  &__warning-content {
    width: 100%;
    height: 100%;
    padding: 80px 0 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;

    @media screen and (max-height: 568px) {
      padding: 80px 0 24px;
    }
  }

  &__warning-txt {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: white;
    margin: auto 0 0;

    @media screen and (max-height: 568px) {
      font-size: 14px;
      line-height: 18px;
      margin-top: 30px;
    }
  }

  &__grade-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
  }

  &__grade {
    margin-top: 0;
    margin-bottom: -8px;
  }

  &__grade-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__pointer {
    height: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .15s linear;
    will-change: transform;
  }
}
