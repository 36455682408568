.Dopplr__ReplaceAvatar {
    &__Content {
        padding: 0 1em;
    }

    &__Actions {
        padding: 1em 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}