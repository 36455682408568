.Avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 1em;

  &__Image {
    width: 4em;
    height: 4em;
    border-radius: 50%;
    background: #c4c4c4;
    margin-bottom: 0.5em;
    box-sizing: border-box;
  }
  &__Name {
    font-size: 0.8em;
  }
}
