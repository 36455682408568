@import '../../styles/mixins.scss';
@import '../../styles/_theme.scss';


#sceneExplorer, #actionTabs {
  z-index: 99999;
}

.Dopplr_ErrorScreen {
  position: absolute;
  z-index: 1500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__Text {
    margin: 0.5em 0;
    text-align: center;
  }
  h1 {
    margin: 0;
  }
}

.Dopplr_FeedMeasurements {
  .Button__Container {
    padding: 0;
    padding-top: 0.5em;

    button {
      font-size: 0.6em;
      border-radius: 5px;
    }
  }
}

.Dopplr_FloatingActions {
  position: absolute;
  top: 5em;
  left: -0.5em;
  // border: 1px solid rgb(220, 220, 220);
  border-radius: 0 1em 1em 0;
  padding-left: 0.5em;
  transition: all 0.3s ease-in-out;

  &.hide {
    left: -6em;
  }
}

.Dopplr_Backgrounds {
  // bottom: 15em;
  // right: 1.5em;
  height: 35px;
  width: 35px;
  border-radius: 13em;
  // position: absolute;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 50%);
  transition: all 0.3s ease-in-out;
  @include disableUserSelect;

  &.hide {
    right: -5em;
  }

  &__FloatingCartIcon {
    width: 20px;
    height: 20px;
  }

  &.Dark {
    background: black;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
.Dopplr_ShareButton {
  // bottom: 11.5em;
  // right: 1.5em;
  margin-top: 1em;
  height: 35px;
  width: 35px;
  border-radius: 13em;
  // position: absolute;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 50%);
  @include disableUserSelect;

  &.Dark {
    background: var(--primaryColor);
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.Dopplr_HideButton {
  // bottom: 11.5em;
  // right: 1.5em;
  margin-top: 1em;
  height: 35px;
  width: 35px;
  border-radius: 13em;
  // position: absolute;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 50%);
  @include disableUserSelect;

  &.Dark {
    background: var(--primaryColor);
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.Dopplr_CloseButton {
  position: absolute;
  z-index: 1999;
  top: 1em;
  left: 0.5em;
  width: 2em;
  height: 2em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: white;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgb(0, 0, 0, 0.25);
  transition: all 0.3s ease-in-out;
  @include disableUserSelect;

  &.hide {
    left: -10em;
  }
}

.Dopplr_Variations {
  top: 40%;
  transform: translateY(-50%);
  left: 1.5em;
  z-index: 2;
  cursor: pointer;
  border-radius: 13em;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 0.5em 0;
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 50%);

  &.Dark {
    background: black;
  }
}

.Dopplr_AddToCart {
  bottom: 1.5em;
  right: 1.5em;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 13em;
  background: white;
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 50%);

  &__FloatingCartIcon {
    width: 20px;
    height: 20px;
  }
}

.BodyVisualiserWrapper {
  max-height: 100% !important;
  height: 100%;
  border-radius: 0 !important;
}

.Dopplr_LoginButton {
  cursor: pointer;
  position: absolute;
  right: 1em;
  font-size: 0.86em;
  padding: 0.3em 0.5em 0.5em;
  border-radius: 6px;
  box-shadow: 1px 2px 0.4em 2px rgba(0, 0, 0, 0.1);
  background: white;
}

.Dopplr_PersonaliseV2 {
  top: 0.5em;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: 1;
  height: 45px;
  width: 45px;
  overflow: hidden;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13em;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  @include disableUserSelect;
  &__Container {
    border: 1px solid rgb(220, 220, 220);
    border-radius: 1em 0 0 1em;
    position: absolute;
    top: 0.5em;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    background: var(--floatingBG);
  }
  &__ActionBar {
    position: relative;
    // background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
}

.Dopplr_CartSize {
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  position: absolute;
  left: 1em;
  top: 0.5em;
  border-radius: 0.5em;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: white;
  padding: 0.3em;

  &__FloatingActionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__FloatingAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0.3em;
    margin-bottom: 0.5em;
    cursor: pointer;

    &.BorderLeft {
      border-left: 1px solid #000;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__FloatingButton {
    width: 30px;
    height: 30px;
    font-size: 0.78em;
    color: var(--primaryColor);
    background: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    border-radius: 13em;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @media screen and (max-width: 450px) {
      width: 22px;
      height: 22px;
      font-size: 0.6em;
    }

    &.selected {
      border: 1px solid var(--primaryColor);
      background: var(--primaryColor);
      color: white;
    }

    &.disabled {
      cursor: not-allowed;
      background: #dCdCdC;
      box-shadow: none;
      text-decoration: line-through;
    }
  }
}

.Dopplr_PrimaryActionsV2 {
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  // position: absolute;
  // left: 1em;
  // top: 4.5em;
  // border-radius: 0.5em;
  // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
  // background: white;
  transition: all 0.3s ease-in-out;

  &__FloatingActionContainer {
    display: flex;
    margin: 0.5em 0.5em 0 0.5em;
    justify-content: center;
    align-items: center;
  }

  &__FloatingActionButton {
    padding: 0;
    flex: 1;
    margin-right: 0.5em;

    .Button__Container {
      padding: 0;
    }
  }

  &__FloatingAction {
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 20px;
    border: 1px solid var(--border1);
    background: var(--backgroundColor);
    cursor: pointer;
    margin: 0 0.5em;

    &__Title {
      font-size: 0.7em;
      font-weight: 600;
    }
  }


  &__FloatingIcon {
    width: 1em;
    height: 1em;
  }

  &__Text {
    color: black;
    padding-left: 0.5em;
    font-size: 0.875rem;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    padding: 0 0.5em;
  }

  &__FloatingButton {
    width: 35px;
    height: 35px;
    font-size: 0.78em;
    color: white;
    background: var(--primaryColor);
    // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    border-radius: 13em;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &.selected {
      background: var(--primaryColor);
      color: white;
    }
  }
}

.Dopplr_HeatmapActionsV2__ToggleButton {
  position: absolute;
  cursor: pointer;
  top: -3em;
  z-index: 999;
  left: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 13em;
  width: 30px;
  height: 30px;
  background: white;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.Dopplr_HeatmapFitScore {
  position: absolute;
  left: 50%;
  padding: 0.5em;
  border-radius: 0.5em;
  font-size: 0.8em;
  color: white;
  background: rgba(0, 0, 0, 0.5)
}


.Dopplr_PersonaliseOptions {
  // user-select: none;
  // -ms-user-select: none;
  // -webkit-user-select: none;
  // position: absolute;
  // right: 1em;
  // top: 3em;
  // border-radius: 35px;
  // background: rgba(0, 0, 0, 0.3);

  // user-select: none;
  // -ms-user-select: none;
  // -webkit-user-select: none;
  // -webkit-user-select: none;
  // position: absolute;
  // top: 50%;
  // left: -32%;
  // transform: translate(-50%, -50%);
  // border-radius: 35px;
  // background: rgba(0, 0, 0, 0.3);

  //Add once sresht came back to toggle option
  // margin-top: 4.5em;
  // border-top: 1px solid white;

  &__ToggleButton {
    position: absolute;
    cursor: pointer;
    top: -2.2em;
    z-index: 1;
    left: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 13em;
    width: 30px;
    height: 30px;
    background: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
  }

  &__FloatingAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70px;
    box-sizing: border-box;
    padding: 0 1em 0 0.4em;
    cursor: pointer;
    // border-bottom: 1px solid white;
    @include disableUserSelect;

    &:last-child {
      border-bottom: none;
    }
  }

  &__FloatingActionLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 55px;
    box-sizing: border-box;
    padding: 0 0.4em 0 0.4em;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
  }

  &__FloatingIcon {
    width: 1em;
    height: 1em;
  }

  &__Text {
    color: var(----textPrimary);
    font-size: 10px;
    padding-top: 0.3em;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
  }

  &__FloatingButton {
    width: 40px;
    height: 40px;
    background: white;
    border: 1px solid rgba(220, 220, 220);
    // box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 50%);
    border-radius: 13em;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &.selected {
      background: #333;
      color: white;
    }
  }
}

.Dopplr_HeatmapActionsV2 {
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  position: absolute;
  left: 2em;
  top: 4em;
  font-size: 0.78em;
  border-radius: 35px;
  background: rgba(0, 0, 0, 0.5);
  // user-select: none;
  // -ms-user-select: none;
  // -webkit-user-select: none;
  // -webkit-user-select: none;
  // position: absolute;
  // top: 50%;
  // left: -32%;
  // transform: translate(-50%, -50%);
  // border-radius: 35px;
  // background: rgba(0, 0, 0, 0.3);

  &__FloatingAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 55px;
    box-sizing: border-box;
    padding: 0 0.8em;
    cursor: pointer;
    border-bottom: 1px solid white;

    &:last-child {
      border-bottom: none;
    }
  }

  &__FloatingIcon {
    width: 1em;
    height: 1em;
  }

  &__Text {
    color: white;
    font-size: 0.48em;
    padding-top: 0.3em;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
  }

  &__FloatingButton {
    width: 30px;
    height: 30px;
    background: white;
    box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 50%);
    border-radius: 13em;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &.selected {
      background: var(--primaryColor);
      color: white;

      &.scaleUp {
        scale: 2;
      }
    }
  }
}

.button-container {
  z-index: 1;

  .add-avatar {
    position: absolute;
    top: 14%;
    left: 0em;
  }

  .add-items {
    position: absolute;
    bottom: 1em;
    right: 0.5em;
    z-index: 1;
  }

  .save-avatar {
    position: absolute;
    bottom: 1em;
    left: 0.5em;
    z-index: 1;
  }

  .FloatingButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 4em;

    &:hover .ToolTipText {
      visibility: visible;
      opacity: 1;
    }

    .ToolTipText {
      visibility: hidden;
      width: 120px;
      font-size: 0.8em;
      background-color: #fff;
      color: #5c5c5c;
      text-align: center;
      padding: 5px 0;
      border-radius: 0 6px 6px 0;
      position: absolute;
      z-index: 1;
      bottom: 3px;
      left: 190%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.16);

      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: -8px;
        margin-left: -18px;
        border-width: 13px;
        border-style: solid;
        border-color: transparent #fff transparent transparent;
      }
    }

    .button-div {
      background: #fff;
      border: 1px solid #878787;
      color: #5c5c5c;
      border-radius: 0.5em;
      padding: 0.1em;
      height: 1.3em;
      width: 2em;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      .size {
        font-size: 1em;
        position: relative;
        top: -1px;
      }

      img {
        width: 1em;
        height: 1em;
      }
    }

    .button-text {
      font-size: 12px;
      padding-top: 0.5em;
      text-align: center;
      font-weight: 500;
      cursor: pointer;
      color: #5c5c5c;
      padding-bottom: 0.5em;
    }
  }
}

// .fab {
//   background-color: transparent;
//   height: 2.5em;
//   width: 2.5em;
//   border-radius: 32px;
//   transition: height 300ms;
//   transition-timing-function: ease;
//   position: absolute;
//   right: 50px;
//   bottom: 50px;
//   text-align: center;
//   overflow: hidden;
// }

// .fab:hover {
//   height: 200px;
// }

// .fab:hover .mainop {
//   transform: rotate(180deg);
// }

// .mainop {
//   margin: auto;
//   height: 2.5em;
//   width: 2.5em;
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   transition: transform 300ms;
//   background: #202020;
//   border-radius: 32px;
//   z-index: 6;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   .mainButton {
//     font-size: 32px;
//     color: #fff;
//     position: relative;
//     top: -5px;
//   }
// }

// .mainopShadow {
//   height: 2.5em;
//   width: 2.5em;
//   border-radius: 32px;
//   position: absolute;
//   right: 50px;
//   bottom: 50px;
//   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
// }

// .minifab {
//   position: relative;
//   width: 2.5em;
//   height: 2.5em;
//   border-radius: 24px;
//   z-index: 5;
//   float: left;
//   margin-bottom: 12px;
//   background: #202020;
//   transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
//   .button-text {
//     font-size: 12px;
//     padding-top: 0.5em;
//     text-align: center;
//     font-weight: 600;
//     cursor: pointer;
//   }
// }

// .minifab:hover {
//   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
// }

// .minifabIcon {
//   margin-top: 12px;
// }

.Dopplr__ActionWrapper {
  flex-direction: column;
  justify-content: center;

  &__Actions {
    display: flex;
    border-radius: 0.5em;
    margin-bottom: 0.5em;
    width: 78%;
    overflow: hidden;
  }

  .Button__Container {
    padding: 0;
    margin: 0;
  }
}

.FloatingButton {
  // border: 0;
  // border-radius: 0;
  // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-weight: 400;
  padding: 0.5em 1em;
  // height: 2.5em;
  font-size: 12px;

  &.mobile {
    font-size: 0.7em;
  }

  &.Left {
    border-right: 1px solid white;
  }

  &.selected {
    border-top: 3px solid #000;
    color: #000;
  }

  &__Icon {
    height: 1.5em;
    width: 1.5em;
    margin: 0 0.5em
  }

  &__Content {
    display: flex;
    align-items: center;

    img {
      margin-right: 1em;
      height: 1.3em;
      width: 1.3em;
    }
  }
}

.RecommendedSizeModal {
  .recommendationSection {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1em;
    line-height: 1.5;
  }
}

.Babylon {
  &__Error {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    background: white;
    z-index: 1;
  }
}

.DopplrScene {
  &__IconContainer {
    position: absolute;
    top: 35%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -35%);
  }

  &__EnamorIconContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
  }

  &__PinchText {
    bottom: 1em;
    position: absolute;
    left: 0;
    right: 0;
    color: white;
    text-align: center;
  }

  &__PinchContainer {
    width: 11em;
    height: 9em;
    position: relative;
    border-radius: 0.5em;
    background: rgba(0, 0, 0, 0.5);
    font-size: 0.7em;
  }

  &__PinchLeft {
    width: 1.2em;
    position: absolute;
    left: 50%;
    top: 35%;
    background: white;
    border: 1px solid;
    border-radius: 5em;
    height: 1.2em;
  }

  &__PinchRight {
    width: 1.2em;
    background: white;
    left: 50%;
    top: 35%;
    border-radius: 5em;
    position: absolute;
    border: 1px solid;
    height: 1.2em;
  }

  &__AnimationImage {
    width: 2.2em;
  }

  &__AnimationContent {
    padding: 0.5em;
    font-size: 0.7em;
    width: 150px;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.5em;
    background: rgba(0, 0, 0, 0.5);

    div {
      padding-bottom: 0.25em;
    }
  }
}

.heatmapLabelComponentV2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  // align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  width: 100px;
  padding: 0.1em;
  border: 0px;
  border-radius: 0.5em;
  // color: rgb(255, 255, 255);
  color: #000;
  z-index: 1;
  // background: rgba(0, 0, 0, 0.5);
  background: #fff;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.5);
  z-index: 1;
  transform: translate3d(50vw, 50px, 0px);

  .textContainer {
    font-weight: 500;
    text-transform: capitalize;
  }

  &::before {
    content: "";
    position: absolute;
    left: -6px;
    // border-right: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid #fff;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
}

.fabricLabelComponentV2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  // align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  width: 100px;
  padding: 0.1em;
  border: 0px;
  border-radius: 0.5em;
  // color: rgb(255, 255, 255);
  color: #000;
  z-index: 1;
  // background: rgba(0, 0, 0, 0.5);
  background: #fff;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.5);
  z-index: 1;
  transform: translate3d(50vw, 50px, 0px);
  @include disableUserSelect;

  &::before {
    content: "";
    position: absolute;
    left: -6px;
    // border-right: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid #fff;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
}


.heatmapLabelComponent {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100px;
  padding: 0.1em;
  border: 0px;
  border-radius: 0.5em;
  color: rgb(255, 255, 255);
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  transform: translate3d(50vw, 50px, 0px);

  &::before {
    content: "";
    position: absolute;
    left: -6px;
    border-right: 5px solid rgba(0, 0, 0, 0.5);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
}

.Dopplr_RecommendedTooltip {
  background: white;
  color: black;
  padding: 0.5em;

  &__Title {
    font-weight: 600;
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }

  &__Content {
    font-weight: 400;
    font-size: 1.1em;
  }
}

.Dopplr_FirstTimeUser {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 20em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  bottom: 25%;
  z-index: 2;

  &__WelcomeText {
    position: absolute;
    top: 1em;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    text-align: center;
    color: var(--primaryColor);

    &__Title {
      font-size: 1.6em;
      font-weight: 600;
    }

    &__Text {
      font-size: 1em;
    }
  }

  &.showInBottom {
    bottom: 1em;
    transition: bottom ease-in 0.2s;
  }

  &__Text {
    color: white;
    align-self: flex-start;
    padding-left: 0.5em;
    font-size: 0.8em;
    position: relative;
    top: 0.5em;
  }

  &__PoweredByDopplr {
    position: absolute;
    right: 0.5em;
    bottom: 1em;
    width: 9em;
    z-index: 2;
  }

  &__Overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
  }
}

.personaliseButton {
  font-size: 0.8em;
  padding: 1em;
}

.Dopplr_LoginAction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: white;
  border-radius: 10em;
  cursor: pointer;
  // position: absolute;
  right: 1em;
  top: 0.5em;
  // z-index: 10;
  border-bottom: none;
}

.Dopplr__CartSection {
  padding: 1em 1em 0;
  background: white;

  .MuiSnackbarContent-message {
    font-size: 0.8em;
  }

  &__Action {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;

    .Button__Container {
      width: auto;
    }

    &__AddItems {
      width: 170px;
    }

    &__CartIcon {
      position: relative;
    }

    &__CartCountContainer {
      position: relative;
    }

    &__CartCount {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10em;
      background: red;
      padding: 0.1em 0.5em;
      font-size: 0.76em;
    }
  }

  &__Card {
    box-sizing: border-box;
    background: #f0f0f0;
    border-radius: 0.5em;
    margin-bottom: 0.5em;

    &__ProductTitle {
      padding: 0.5em;
      font-weight: 500;
      font-size: 0.8em;
    }

    &__Link {
      cursor: pointer;
    }

    &__ProductContent {
      display: flex;
      position: relative;
    }

    &__Content {
      padding: 0 1em;

      &.multiOptions {
        flex: 1;
      }
    }

    &__Prices {
      padding-left: 1em;
    }

    &__SellingPrice {
      padding: 0 0.2em;
      font-weight: 600;
      font-size: 1.1em;
    }

    &__DiscountApplied {
      display: flex;
      align-items: center;
    }

    &__Mrp {
      padding: 0 0.2em;
      font-size: 0.8em;
      font-size: 0.8em;
      text-decoration: line-through;
    }

    &__Discount {
      padding: 0 0.2em;
      font-size: 0.8em;
      color: red;
    }

    &__Bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__Action {
      // position: absolute;
      // right: 0em;
      // top: -2em;
      width: 10em;
      padding-left: 0.5em;

      &__Content {
        display: flex;
        align-items: center;

        span {
          font-size: 0.8em;
        }
      }

      .Button__Container {
        margin-bottom: 0;
      }

      &__Added {
        font-size: 0.8em;
        color: green;
        padding-left: 1em;
        display: flex;
        align-items: center;
      }
    }

    &__SizeSection {
      position: relative;
      margin: 1em 0 0.5em 0;
      border: 1px solid #5C5C5C;
      border-radius: 0.5em;

      &__Title {
        position: absolute;
        padding: 0 0.5em;
        background: #f0f0f0;
        top: -0.8em;
        left: 0.5em;
        font-size: 0.8em;
      }

      &__Label {
        margin: 1em 1em 0 1em;
        font-size: 0.8em;
      }

      &__RecommendedSize {
        font-size: 0.65em;
        border-radius: 0 0 0.5em 0.5em;
        padding: 0.5em 1em;
        margin-bottom: 0.1em;
        background: white;

        span {
          font-weight: 500;
        }
      }
    }

    &__RecommendedSize {
      border-radius: 0.5em;
      margin-top: 0.5em;
      font-size: 0.7em;
      background: white;
      padding: 0.2em 0.5em;

      span {
        font-weight: 500;
      }
    }

    &__Sizes {
      display: flex;
      margin-top: 0.5em;
      flex-wrap: wrap;
    }

    &__Image {
      min-width: 100px;
      height: 142px;
      margin: 0.5em;
      border-radius: 0.5em;
      overflow: hidden;
      @media screen and (max-width: 450px) {
        height: 100px;
        width: 80px;
      }

      &.multiOptions {
        width: 130px !important;
        height: 200px !important;
        margin: 1em 0.5em
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.CustomiseIcon {
  width: 1.1em;
  height: 1.1em;
  color: #6D6D6D;
  position: relative;
  top: 0.1em;
  padding: 0 0.5em 0 0;
}

.Dopplr__PrivacyContent {
  position: absolute;
  top: -6em;
  font-size: 0.8em;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  color: #888888;

  a {
    color: var(--primaryColor);
  }
}