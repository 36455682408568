@import '../../styles/mixins.scss';
@import '../../styles/animations.scss';

.Dopplr__SkyboxWrapper {
    position: absolute;
    padding-bottom: 3em;
    left: 0;
    right: 0;
    background: white;
    padding: 0.5em;
    bottom: -9em;
    z-index: 1;
    padding-bottom: 1.5em;
    box-shadow: 0 -2px 2px 0 rgb(0, 0, 0, 0.25);
    transition: bottom 0.2s ease-in;
    -webkit-transition: bottom 0.2s ease-in;
    -moz-transition: bottom 0.2s ease-in;

    &__Title {
        text-align: center;
        padding-bottom: 0.5em;
        font-size: 0.86em;
    }

    &.show {
        bottom: 0em;
    }

    &__ToggleButton {
        position: absolute;
        top: -2.2em;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        background: white;
        width: 2em;
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 0 4px 10px rgb(0, 0, 0, 0.25);
        z-index: 1;
        cursor: pointer;

        img {
            width: 60%;
            transform: rotate(0deg);

            &.show {
                transform: rotate(180deg);
            }

            transition: transform 0.2s ease-in;
        }
    }

    &__List {
        display: flex;
        align-items: center;
        // box-shadow: 0 -2px 2px 0 rgb(0, 0, 0, 0.25);
        overflow-x: scroll;
        @include hideScrollBar;

        &__Skybox {
            &__Item {
                height: 3em;
                border-radius: 0.5em;
                overflow: hidden;
                margin-right: 0.5em;
                cursor: pointer;
                box-sizing: border-box;
                border: 1px solid #5c5c5c;
                min-width: 96px;
                max-width: 7em;
                &.selected {
                    border: 1px solid black;
                }

                &.overlay {
                    opacity: 0.6;
                }

                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}