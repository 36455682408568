@import "../../styles/mixins.scss";
.Home {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: white;
  @include hideScrollBar;
  .Header {
    display: flex;
    max-height: 50px;
    background: black;
    padding: 1em;
    img {
      height: 100%;
    }
  }
  .Footer {
    height: 100px;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    background: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
  }
  .PageWrapper {
    display: flex;
  }
  .ProductList {
    // display: flex;
    margin: 0.5em 0;
    // width: 100%;
    flex-wrap: wrap;
    .ProductCard {
      cursor: pointer;
      // width: 20em;
      // height: 20em;
      &__Image {
        // width: 100%;
        // height: 38em;
        width: 100px;
        border-radius: 10px;
        border: 1px solid #e3e3e3;
        &:hover {
          background-color: #f1f1f1;
        }
        &.overlay {
          opacity: 0.6;
        }
        &.selected {
          border: 1px solid #171717;
        }
      }
      // &__Description {
      //   padding: 1em 0.5em;
      //   color: white;
      //   &.selectedText {
      //     color: #f1df67;
      //   }
      // }
      margin: 1em;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
  .leftContent {
    display: flex;
  }
  .SelectedProduct {
    display: flex;
    color: black;
    margin: 1.5em;
    border-radius: 0.25em;
    overflow: hidden;
    flex-wrap: wrap;
    &__Image {
      border-radius: 0.25em;
      overflow: hidden;
      min-width: 320px;
      max-width: 500px;
      flex: 1;
      // border: 1px solid;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__Name {
      font-size: 1.5em;
      font-weight: 500;
      margin: 1em 0;
    }
    &__PriceContainer {
      display: flex;
      justify-content: space-between;
      padding: 0.5em 0;
    }
    &__Price {
      font-size: 1.5em;
      font-weight: 500;
    }
    &__Action {
      display: flex;
      .Button__Container {
        width: 250px;
        padding: 0;
        padding-right: 0.5em;
        .Button {
          height: 50px;
        }
      }
    }
    &__Label {
      font-size: 1.5em;
      margin: 1em 0;
    }
    &__Description {
      flex: 1;
      margin: 1em;
      color: black;
    }
  }
}
