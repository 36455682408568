.Dopplr_Layout {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 1;
  &.desktop {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
    // background: #181923;
    // background: black;
    background: white;
    color: #000;
    position: absolute;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  background: #fff;
}
