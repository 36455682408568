.AppLoader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: white;

    &__Progress {
        font-size: 0.8em;
        padding-top: 0.5em;
        color: var(--textColor);
    }
    .CavaLoader {
        width: 10em;
        height: 10em;
    }

    .LoaderContainer {
        border-radius: 69%;
        background: white;
        box-shadow: 0px 1px 2px rgb(0, 0, 0.01);
        width: 10em;
        height: 10em;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    .SuperkicksLoaderContainer {
        background: white;
        // box-shadow: 0px 1px 2px rgb(0, 0, 0.01);
        width: 10em;
        height: 10em;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
}