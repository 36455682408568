@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.CloseButton {
  padding: 0 0.5em;
  width: 1.5em;
  height: 1.5em;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.01);
  box-sizing: border-box;
  box-shadow: 1px 1px 0.4em 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  @include ripple(white, rgba(0, 0, 0, 0.05));
  @include appearZoomIn(0.5s);

  &__Icon {
    font-size: 0.75em;
    padding-left: 1px;
    font-weight: 600;
  }
}

.WhiteHeaderButton {
  background: rgba(255, 255, 255, 0.2);
  @include ripple(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
}
