.InputWrapper {
  border-radius: 8px;
  display: flex;
  height: 2.5em;
  background-color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  input {
    appearance: none;
    height: 2.5em;
    border-radius: 8px;
    padding-left: 1em;
    flex: 1 1;
    border: 1px solid #484848;
    background: transparent;
    color: #484848;
    &:focus {
      outline: none;
    }
  }
  .toggleField {
    position: absolute;
    right: 0.5em;
    font-size: 0.8em;
    cursor: pointer;
  }
}
