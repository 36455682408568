.Dopplr-Header {
  // width: 100%;
  max-height: 4em;
  min-height: 2em;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  z-index: 2;
  position: relative;
  // background: #181923;
  // background: black;
  // background: white;
  // box-shadow: 1px 1px 0.4em 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: none;
  .white {
    color: white;
  }
}