@mixin ripple($color, $rippleColor) {
  background-position: center;
  will-change: background-color, background-size;
  transition: background-color 0.5s, background-size 0.5s;

  &:hover {
    background: #{$color} radial-gradient(circle, transparent 1%, #{$rippleColor} 1%) center/20000%;
  }
  &:active {
    background-color: $rippleColor;
    background-size: 100%;
    transition: background-color 0.5s, background-size 0.5s;
  }
}

@mixin hideScrollBar {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
    height: 0;
    background: transparent;
    visibility: hidden;

    &-thumb {
      width: 0 !important;
      height: 0;
      background: transparent;
      visibility: hidden;
    }
    &-track {
      width: 0 !important;
      height: 0;
      background: transparent;
      visibility: hidden;
    }
  }
}

@mixin disableUserSelect {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
}

@mixin text-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin hide-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin discountedPrice {
  margin-right: 0.5em;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    border: 1px solid rgba(255, 0, 0, 1);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 48%;
  }
}
