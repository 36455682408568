.Dopplr_Title {
  font-size: 1em;
  font-weight: 500;
  //Positioning
  position: absolute;
  left: 50%;
  color:#000;
  transform: translateX(-55%);
  &.white {
    color: #fff;
  }
}
