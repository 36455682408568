.agreementCheckbox {
	width: 16px;
	height: 16px;
	margin: 0 10px 0 0;
	position: relative;
	-webkit-appearance: none;
	-moz-appearance: none;
	cursor: pointer;
	&::before {
		border: 1px solid var(--border1);
		content: "\00a0";
		display: inline-block;
		height: 18px;
		min-width: 18px;
		min-height: 18px;
		padding: 0;
		vertical-align: top;
		border-radius: 5px;
		width: 18px;
		background: white;
		position: absolute;
		top: -2px;
		left: -2px;
	}
	&:checked::before {
		content: "\2714";
		font-size: 13px;
		text-align: center;
		color: #000;
		border: 1px solid #000;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.16);
		background: #f5df4d;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&:focus {
		outline: 0;
	}
}
