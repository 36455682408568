@import '../../styles/mixins.scss';
@import '../../styles/animations.scss';

.Dopplr_Customize {
    height: 100%;

    &.background {
        background: var(--backgroundColorSecondary);
    }

    &__BustMeasurementContainer {
        text-align: center;

        &__Img {
            width: 80%;
        }

        &__Selections {
            height: 100%;
            padding: 0 1em;
            overflow-y: scroll;
            background: var(--backgroundColorSecondary);
            @include hideScrollBar;
        }
    }

    &__Video {
        padding: 0.5em 1em;
        font-size: 0.86em;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__Title {
        text-align: center;
        top: 18%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, 0);
        position: absolute;
    }

    &__Heading {
        font-size: 1.5em;
        font-weight: 500;
    }

    &__Text {
        color: #888888;
        padding-bottom: 1em;
    }

    &__Content {
        color: #888888;
    }

    &__RemoveImage {
        display: flex;
        justify-content: center;
        align-items: center;

        // text-decoration: underline;
        span {
            border-bottom: 1px solid;
            cursor: pointer;
        }
    }
}

.FaceSelection {
    position: absolute;
    bottom: 0;
    background: white;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    border-radius: 1.5em 1.5em 0 0;
    z-index: 2;

    &__SelfieOptionsCard {
        min-height: 1em;
    }

    &__SelfieOptions {
        display: flex;
        align-items: center;
    }

    &__SelfieOption {
        margin: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 0.86em;
    }

    &__Error {
        color: red;
        font-size: 0.8em;
        margin: 1em;
        height: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__GenderSelection {
        padding-bottom: 0.5em;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__Title {
            margin-bottom: 0.5em;
            font-size: 0.9em;
        }
    }

    &__BodyPlaceHolder {
        // position: absolute;
        // top: 60%;
        // left: 50%;
        // transform: translate(-50%, -40%);
        // height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;

        &.background {
            background: var(--backgroundColorSecondary);
        }

        &__BodyImg {
            height: 115%;
            position: absolute;
            top: 85%;
            left: 51%;
            transform: translate(-50%, -50%);
        }
    }

    &__FaceCircle {
        border: 1px solid #000;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        top: 37%;
        left: 50%;
        width: 14em;
        height: 14em;
        background: rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    &__SelectedFace {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 40%;
        left: 50%;

        &__Circle {
            width: 18em;
            height: 18em;
            border: 1px solid #000;
            border-radius: 50%;
            overflow: hidden;
        }
    }

    &__Title {
        position: absolute;
    }

    &__Heading {
        font-size: 1.5em;
        font-weight: 500;
        padding: 0.5em 0.5em 0 1em;
    }

    &__Instructions {
        text-align: center;
        padding-bottom: 1em;
        margin-bottom: 3em;

        &__Avoid {
            border: 1px solid #EC3838;
            border-radius: 1em;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5em 0 0.5em 0;
            position: relative;

            &__Label {
                position: absolute;
                top: -0.7em;
                font-size: 0.7em;
                color: #EC3838;
                background: white;
                padding: 0 0.5em;
                left: 3.5em;
            }
        }

        &__Follow {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5em 1em;

            img {
                width: 60px;
                height: 60px;
            }
        }

        &__InstructionItem {
            flex: 1;
            text-align: center;
            font-size: 0.7em;

            img {
                width: 60px;
                height: 60px;
            }
        }
    }
}

.Measurements {
    position: absolute;
    bottom: 0;
    background: white;
    left: 0;
    right: 0;
    border-radius: 1.5em 1.5em 0 0;

    &__List {
        @include hideScrollBar;
    }

    &__Heading {
        font-size: 1.5em;
        font-weight: 500;
        padding: 0.5em 0.5em 0 1em;
    }

    &__FormInput {
        margin: 1em 0 2em 0;
    }

    &__FormLabel {
        margin-bottom: 1em;
    }

    &__Height {
        display: flex;
        align-items: start;
    }

    &__Select {
        flex: 1;
        margin-right: 1em;
        border-radius: 1em !important;
    }

    &__UnitSelect {
        margin-right: 1em;
        border-radius: 1em !important;
    }

    &__TextField {
        margin-right: 1em;
    }

    &__SizeLabel {
        margin-bottom: 0.5em;
    }

    &__SizeItem {
        margin: 0 1em 1em;
    }

    &__SizeContainer {
        background: #F7F5F2;
        border-radius: 1em 1em 0 0;
        padding-bottom: 4em;
        padding-top: 1em;
    }
}


.BustSelections {
    height: 95%;
    padding: 0 1em;
    overflow-y: scroll;
    background: var(--backgroundColorSecondary);
    @include hideScrollBar;

    &__HeadingContainer {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        padding: 0.5em 0;
        background: var(--backgroundColorSecondary);
    }

    &__ShapeContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-top: 3em;
        height: 85%;
        // padding-bottom: 2em;
        &__Img {
            overflow: hidden;
            height: 160px;
            min-width: 180px;
            img {
                // width: 100%;
                height: 100%;
            }
        }

        &__Shape {
            margin: 0.5em;
            flex: 1;
            cursor: pointer;
            display: flex;
            align-items: center;
            background: white;
            border-radius: 1em;
            padding-left: 0.5em;
            padding-right: 0.5em;
            &.selected {
                border: 2px solid #000;
            }
        }
    }

}

.TuneAvatar {
    height: 100%;
    padding: 0 1em;
    overflow-y: scroll;
    background: var(--backgroundColorSecondary);
    @include hideScrollBar;

    &__HeadingContainer {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        padding: 0.5em 0;
        background: var(--backgroundColorSecondary);
    }

    &__ShapeContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-top: 5em;
        padding-bottom: 5em;

        &__Img {
            border-radius: 1.5em;
            overflow: hidden;
            width: 150px;
            height: 200px;

            img {
                width: 100%;
                height: 100%;
            }

            &.selected {
                border: 1px solid #333;
            }
        }

        &__Shape {
            margin: 0.5em;
            text-align: center;
            cursor: pointer;
        }
    }

}

.Dopplr_StepProgress {
    display: flex;
    padding: 1em 1em 0.5em 1em;

    &__Step {
        width: 100%;
        height: 0.5em;
        border-radius: 5px;
        margin: 0 0.3em;
        background: var(--border1);

        &.active {
            background: var(--primaryColor);
        }
    }
}