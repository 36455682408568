.BasicInfo {
  &__Title {
    background: #3a3a3a;
    padding: 0.5em 1em;
    margin-bottom: 1em;
  }
  &__Description {
    &__Label {
      color: #3a3a3a;
      width: 30%;
    }
    &__Value {

    }
    &__Row {
        display: flex;
        align-items: center;
        margin: 0.5em 1em;
        font-size: 0.8em;
    }
  }
}
