@import 'styles/mixins.scss';

.BackButton {
  // padding: 0.5em;
  width: 1.5em;
  height: 1.5em;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  // border: 1px solid rgba(0, 0, 0, 0.05);
  // box-sizing: border-box;
  box-shadow: 1px 1px 0.4em 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  @include ripple(white, rgba(0, 0, 0, 0.05));
  background: white;
  &__Icon {
    width: 1em;
    height: 1em;
    padding-right: 1px;
    cursor: pointer;
  }
}

.searchBack {
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 1.2em;
    height: 1.2em;
  }
}

.WhiteHeaderButton {
  background: rgba(255, 255, 255, 0.2);
  @include ripple(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
}
