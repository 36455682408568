.WO_OF_FloatingButtonContainer {
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
  padding: 0 0.5em;
  transition: all 0.3s ease-in-out;
  &.hide {
    bottom: -50em;
  }
}