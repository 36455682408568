.IframeSection {
  position: absolute;
  width: 550px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  box-shadow: -2px 1px 2px 0px rgba(0, 0, 0, 0.8);
  transform: translateX(100%);
  background: white;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  &.show {
    animation: appearFromRight 0.3s linear forwards;
  }

  &.hide {
    animation: disappearToRight 0.3s linear forwards;
  }

  &.mobile {
    width: 100%;
  }

  #dopplr-wrapper {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: white;
    .Dopplr__DoubleArrowLeft {
      position: absolute;
      top: 50%;
      left: 1.5em;
      display: flex;
      // background: white;
      width: 35px;
      height: 35px;
      border-radius: 13em;
      z-index: 1;
      justify-content: center;
      // box-shadow: 1px 1px 1px rgb(0, 0, 0, 0.5);
      align-items: center;
      // transform: rotate(360deg) translateZ(0px);
    }
  }

  #myFrame {
    z-index: 1;
    position: relative;
  }

  .CrossButton {
    position: absolute;
    top: 1em;
    left: 2em;
    z-index: 1;
  }

  .DopplrPluginButton {
    width: 100px;
    height: 100px;
    border-radius: 60%;
    background: black;
    border: 1px solid black;
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: -11%;
    z-index: 0;
    transform: rotate(-90deg);
  }

  @keyframes appearFromRight {
    0% {
      transform: translateX(100%);
    }

    95% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(1%);
    }
  }

  @keyframes disappearToRight {
    0% {
      transform: translateX(0%);
    }

    95% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(101%);
    }
  }
}