.ChooseAvatar {
  display: flex;
  flex-direction: column;
  padding: 0em 1em 1em 1em;
  &__Title {
    padding: 1em 0;
  }
  &__AvatarContainer {
    display: flex;
    padding-bottom: 0.5em;
  }
  &__ActionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .Button__Container {
      width: 300px;
    }
  }
}
