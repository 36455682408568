.Dopplr_UploadAssets {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: scroll;
    &__UploadedFiles {
        margin-left: 1em;
    }
    &__Error {
        color: red;
        margin-top: 0.5em;
        margin-left: 1em;
    }
    &__Success {
        color: green;
        margin-top: 0.5em;
        margin-left: 1em;
    }
    &__Form {
        max-width: 500px;
        h1 {
            padding-left: 0.5em;
        }
        padding: 1em;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        margin: 1em;
        border-radius: 1em;
    }
}