@import '../../styles/mixins.scss';
@import '../../styles/animations.scss';

$animationDuration: 0.2s;

.HalfCard__Container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1400;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  &__ToggleButton {
    position: absolute;
    top: -2.2em;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    background: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;

    img {
      width: 60%;
      transform: rotate(0deg);

      &.show {
        transform: rotate(180deg);
      }

      transition: transform 0.2s ease-in;
    }
  }
  &__Header {
    z-index: 4;
    will-change: visibility opacity;
    transition: opacity #{$animationDuration} linear,
    visibility #{$animationDuration} linear;
  }

  &__Close {
    position: absolute;
    top: 1em;
    right: 1em;

    width: 2em;
    height: 2em;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    cursor: pointer;
    z-index: 5;

    color: #000;
    font-weight: 800;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    will-change: opacity;
    transition: opacity #{$animationDuration} linear;

    &:active {
      opacity: 0.75;
      outline: none;
    }
  }

  &__Wrapper {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.25);
    min-height: 10em;
    max-height: 88%;
    // overflow: scroll;
    z-index: 2;

    // background-color: white;
    // background-color: #4A4C59;
    &.animate {
      -webkit-animation-name: slideupWithWiggle;
      animation-name: slideupWithWiggle;
      -webkit-animation-duration: 0.3s;
      animation-duration: 0.3s;

      will-change: transform, opacity;
      transition: transform #{$animationDuration} linear,
      opacity #{$animationDuration} linear;
    }

    &.white-bg {
      background: white;
    }

    @include hideScrollBar;

    &__Header {
      // background: #4A4C59;
      background: white;
      position: relative;
      padding: 0.5em 1em;
      height: 30px;
      // min-height: 60px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      &__Title {
        font-size: 1.1em;
        font-weight: 600;
        width: 90%;
      }

      &__RightButton {
        position: absolute;
        top: 0.5em;
        right: 1em;
      }
    }

    &__Body {
      overflow: scroll;
      height: 94%;
      @include hideScrollBar;
    }
  }

  will-change: visibility,
  background-color;
  transition: visibility #{$animationDuration} linear,
  background-color #{$animationDuration} linear;

  &.show {
    visibility: visible;

    .Modal__Header {
      opacity: 1;
    }

    .Modal__HalfCard {
      transform: translateY(0);
      opacity: 1;
    }

    .Modal__FullCard {
      opacity: 1;
    }

    .Modal__Close {
      opacity: 1;
    }
  }

  &.hidden {
    visibility: hidden;
    background-color: transparent;
    filter: blur(1em);

    .Modal__Header {
      opacity: 0;
    }

    .Modal__HalfCard {
      transform: translateY(100%);
      opacity: 0;
    }

    .Modal__FullCard {
      opacity: 0;
    }

    .Modal__Close {
      opacity: 0;
    }
  }
}