.CarouselContainer {
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 6em rgba(0, 0, 0, 0.1);
  // border-radius: 1em;
  overflow: hidden;

  .slick-dots {
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    position: absolute;
    left: 0.2em;
    bottom: 0.1em;

    li {
      margin-right: 5px;

      button {
        margin: 2px !important;
        font-size: 0;
        line-height: 0;
        display: block;
        width: 28px;
        height: 4px;
        padding: 0;
        cursor: pointer;
        /* color: transparent; */
        border: 0;
        outline: 0;
        background: #000000;
        opacity: 0.15;
        border-radius: 15px;

        &::before {
          content: '' !important;
        }
      }
    }
  }

  .slick-active {
    box-shadow: none !important;
    // border-radius: 1em;

    button {
      background: whitesmoke !important;
      opacity: 1 !important;
    }
  }

  .slick-active.slick-current {
    box-shadow: none !important;
    opacity: 1 !important;
    transform: scale(1) !important;
    transition: all 0.3s linear;
    // border-radius: 1em;

    button {
      background: whitesmoke !important;
      opacity: 1 !important;
    }
  }

  .slick-prev {
    left: 0px;
    z-index: 1;

    &::before {
      color: black;
      font-size: 50px;
    }
  }

  .slick-next {
    right: 35px;
    z-index: 1;

    &::before {
      color: black;
      font-size: 50px;
    }
  }

  .slick-initialized .slick-slide {
    background-color: whitesmoke;
    opacity: 0.5;
    transform: scale(0.7);
    // margin: 0.5em;
    // border-radius: 1em;
    // outline: none;

    div {
      // border-radius: 1em;
      display: flex !important;
      justify-content: center;
      align-items: center;
      // border-radius: 1em;
      // outline: none;
    }

    .slick-active img {
      width: 60px;
      height: 60px;
      min-height: 60px;
    }

    img {
      margin: 0.1em;
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
      outline: none;
      background: white;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }

  .slick-slider {
    outline: none;
    background: whitesmoke;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    outline: none;
    background: whitesmoke;
  }

  span {
    display: flex;
  }

  .BannerImage {
    width: 100%;
    // height: 60px;
    // height: 90px;
    min-height: 100px;
    height: 100px;
    min-width: 100px;
    overflow: hidden;
    border: none;
    outline: none;
    object-fit: cover;
    cursor: pointer;
    padding: 0.2em;
  }

  .BannerImageSkeleton {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0.5em
  }

  @media screen and (max-width: 500px) {
    .BannerImage {
      min-width: 80px;
      max-width: 80px;
      min-height: 80px;
      max-height: 80px;
      width: 80px;
      height: 80px;
    }
  }
}