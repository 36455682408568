:root {
    --primaryColor: #333333;
    --tranparentDarkBg: rgba(0, 0, 0, 0.6);
    --floatingBG: transparent;
    --backgroundColorSecondary: #F7F2ED;
    --secondaryColor: #EEEEEE;
    --secondaryBG: #000; 
    --backgroundColor: #EBEBEB;
    --selectBG: #F7F2ED;
    --border1: #CCCCCC;
    --textColor: #5C5C5C;
    --textPrimary: #000;
    --text-secondary: #fff;
  }
  
  .theme-beinghuman {
    --primaryColor: #333333;
    --tranparentDarkBg: rgba(0, 0, 0, 0.6);
    --floatingBG: transparent;
    --backgroundColorSecondary: #F7F2ED;
    --secondaryColor: #EEEEEE;
    --secondaryBG: #000; 
    --backgroundColor: #EBEBEB;
    --selectBG: #F7F2ED;
    --border1: #CCCCCC;
    --textColor: #5C5C5C;
    --textPrimary: #000;
    --text-secondary: #fff;
  }
  
  .theme-superkicks {
    --primaryColor: #333333;
    --tranparentDarkBg: rgba(0, 0, 0, 0.6);
    --floatingBG: transparent;
    --backgroundColorSecondary: #F7F2ED;
    --secondaryColor: #EEEEEE;
    --secondaryBG: #000; 
    --backgroundColor: #EBEBEB;
    --selectBG: #EBEBEB;
    --border1: #CCCCCC;
    --textColor: #5C5C5C;
    --textPrimary: #000;
    --text-secondary: #fff;
  }
  .theme-enamor {
    --primaryColor: #ED0380;
    --tranparentDarkBg: rgba(0, 0, 0, 0.6);
    --floatingBG: #F9E6D9;
    --backgroundColorSecondary: #F7F2ED;
    --secondaryColor: #F9E6D9;
    --backgroundColor: #F9E6D9;
    --secondaryBG: #F9E6D9; 
    --selectBG: #F9E6D9;
    --border1: #F9E6D9;
    --textColor: #888888;
    --textPrimary: #000;
    --text-secondary: #fff;
  }