.Dopplr_ToggleProductPart {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5em;
}

.Dopplr_HeatmapActions {
  position: absolute;
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.25);
  padding-bottom: 0em;
  bottom: 0;
  left: 0;
  right: 0.5em;
  padding-bottom: 0em;
  background: white;
  border-radius: 10px 10px 0 0;
  z-index: 2;

  &__Title {
    text-align: center;
    padding-bottom: 0.5em;
    font-size: 0.86em;
    margin: 0.5em;
    text-align: center;
  }
  &__ToggleButton {
    position: absolute;
    top: -2.2em;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    background: white;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgb(0, 0, 0, 0.25);
    z-index: 1;
    cursor: pointer;

    img {
      width: 60%;
      transform: rotate(0deg);

      &.show {
        transform: rotate(180deg);
      }

      transition: transform 0.2s ease-in;
    }
  }
}