.RangeSlider {
  width: 100%;
  height: 85px;
  padding: 0.5em 1em 0.5em 1em;
  box-sizing: border-box;

  &__Label {
    // margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8em;

    span {
      font-weight: lighter;
    }
  }

  &__Input {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__InputWrapper {
    width: 60px;
    height: 20px;
    padding: 0 0.5em;
    input {
      width: 100%;
    }
  }

  &__RangeWrapper {
    width: calc(100% - 100px);
    position: relative;
    margin-bottom: 0.5em;
    padding-right: 0.5em;
    font-size: 0.8em;

    &__MinValue {
      position: absolute;
      left: 0;
      top: 2em;
    }

    &__MaxValue {
      position: absolute;
      right: 0;
      top: 2em;
    }
  }

  &__MeasurementRange {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background: #eee;
    outline: none;
    padding: 0;
    margin: 0;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #000;
      cursor: pointer;
      -webkit-transition: background 0.15s ease-in-out;
      transition: background 0.15s ease-in-out;
    }

    &::-webkit-slider-thumb:hover {
      background: #000;
    }

    &:active {
      &::-webkit-slider-thumb {
        background: #000;
      }

      &::-moz-range-thumb {
        background: #000;
      }
    }

    &::-moz-range-thumb {
      width: 20px;
      height: 20px;
      border: 0;
      border-radius: 50%;
      background: #000;
      cursor: pointer;
      -webkit-transition: background 0.15s ease-in-out;
      transition: background 0.15s ease-in-out;
      // }
      // &::-moz-range-thumb:hover {
      //   background: #000;
      // }
    }

    &__InputWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      top: -1.2em;
    }

    &__Unit {
      font-size: 0.8em;
      line-height: 1;
    }

    &__MeasurementInput {
      display: inline-block;
      position: relative;
      width: 60px;
      top: 10px;
      color: #959595;
      background-color: #f5f7fa;
      line-height: 20px;
      text-align: center;
      border-radius: 3px;
      padding: 0 5px;
      margin-left: 8px;
      border: 1px solid;

      &:hover {
        outline: none;
        border-color: #171717;
        color: #171717;
        background-color: #fff;
      }

      &:focus {
        outline: none;
        border: 2px solid #171717;
        color: #171717;
        background-color: #fff;
      }
    }

    input::-moz-focus-inner,
    input::-moz-focus-outer {
      border: 0;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    ::-moz-range-track {
      background: #000;
      border: 0;
    }
  }
}