@import "../../styles/mixins.scss";

.Dopplr__Products {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: white;

  .Header {
    display: flex;
    max-height: 50px;
    background: black;
    padding: 1em;
    justify-content: space-between;
    align-items: center;
    img {
      height: 100%;
    }
  }

  .Footer {
    height: 100px;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    background: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
  }

  .PageWrapper {
    display: flex;
    height: 90%;
  }

  .leftContent {
    height: 100%;
    padding-bottom: 1em;
    overflow-y: scroll;
    @include hideScrollBar;
  }

  .ProductList {
    // display: flex;
    margin: 0.5em 0;
    // width: 100%;
    flex-wrap: wrap;

    .ProductCard {
      cursor: pointer;

      // width: 20em;
      // height: 20em;
      &__Image {
        // width: 100%;
        // height: 38em;
        width: 60px;
        height: 60px;
        border-radius: 10px;
        border: 1px solid #e3e3e3;

        &:hover {
          background-color: #f1f1f1;
        }

        &.overlay {
          opacity: 0.6;
        }

        &.selected {
          border: 1px solid #171717;
        }
      }

      // &__Description {
      //   padding: 1em 0.5em;
      //   color: white;
      //   &.selectedText {
      //     color: #f1df67;
      //   }
      // }
      margin: 1em;

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }

  .SelectedProduct {
    display: flex;
    align-items: flex-start;
    color: black;
    margin: 1em;
    border-radius: 0.25em;
    overflow: hidden;
    flex-wrap: wrap;
    flex: 1;
    overflow-y: scroll;
    @include hideScrollBar;

    &__Image {
      border-radius: 0.25em;
      overflow: hidden;
      min-width: 200px;
      max-width: 540px;
      margin-right: 1em;

      img {
        width: 100%;
        object-fit: contain;
        height: 50%;
      }
    }

    &__Name {
      font-size: 1.5em;
      font-weight: 500;
      margin: 0.5em 0;
    }

    &__PriceContainer {
      display: flex;
      flex-direction: column;
      padding: 0.5em 0;
    }

    &__Price {
      font-size: 1.5em;
      font-weight: 500;
    }

    &__Action {
      display: flex;

      .Button__Container {
        width: 250px;
        padding: 0;
        padding-right: 0.5em;

        .Button {
          height: 50px;
        }
      }
    }

    &__Label {
      font-size: 1.5em;
      margin: 1em 0;
    }

    &__Description {
      flex: 1;
      color: black;
      overflow-y: scroll;
      min-width: 200px;
      @include hideScrollBar;
    }

    ul {
      margin: 0.5em;
    }
  }

  .DopplrPluginButtonMobile {
    width: 60px;
    height: 60px;
    border-radius: 60%;
    background: black;
    color: white;
    border: 1px solid black;
    font-size: 12px;
    position: absolute;
    bottom: 2%;
    right: 5%;
    z-index: 5;
  }
}