@import '../../styles/mixins.scss';

.SelfieModuleContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: white;

    // overflow-y: scroll;
    // @include hideScrollBar;
    &__Actions {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        right: 0;
        align-items: center;
        padding: 0.5em 1em;
        z-index: 1;

        &__Text {
            font-size: 0.86em;
            color: white;
            padding: 0 1.5em 0.5em 1.5em;
            text-align: center;
            margin-bottom: 1em;
        }

        &__Capture {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            img {
                width: 50px;
                cursor: pointer;

                &:hover {
                    transform: scale(1.1);
                }
            }

            &__Text {
                padding-top: 0.5em;
                font-size: 0.7em;
                height: 1.5em;
                color: white;
            }
        }
    }

    &__Capture {
        z-index: 10;
        position: relative;
        background: white;
    }

    &__ImagePreview {
        z-index: 10;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        background: black;

        img {
            // width: 100%;
            height: 100%;
        }

        &__Close {
            position: absolute;
            top: 1em;
            right: 1em;
            width: 2em;
            height: 2em;
            color: white;
            padding: 0.5em;
            background: #636364;

            .CloseButton__Icon {
                font-size: 0.7em;
            }
        }
    }
}

.Dopplr {
    &__SelfieModuleContainer {
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__Logo {
            margin: 1em 0;
        }

        &__Heading {
            font-size: 1.2em;
            padding-bottom: 1em;
            text-align: center;
        }

        &__ImagePreview {
            display: flex;
            background: #4B4B4B;
            width: 265px;
            height: 293px;
            justify-content: center;
            align-items: center;
            border-radius: 1em;
            margin-bottom: 1em;

        }

        &__ActionButton {
            font-weight: 500;
        }

        &__Delete {
            position: absolute;
            bottom: -1em;
            right: 0;
            background: white;
            width: 2em;
            height: 2em;
            display: flex;
            justify-content: center;
            border-radius: 1em;
            align-items: center;
            cursor: pointer;
            img {
                width: 70%;
                height: 70%;
            }
        }

        &__Instructions {
            &__Heading {
                font-size: 0.9em;
                margin-bottom: 0.5em;
            }
            &__List {
                margin:0;
                padding-left: 1em;
            }
            &__Content {
                font-size: 0.8em;
            }
        }

        &__UploadImageContainer {
            padding: 0.5em 1em 0 1em;
            &__UploadedImage {
                width: 265px;
                height: 293px;
                border: 1px solid #dedede;
                border-radius: 10px;
                min-width: 265px;
                min-height: 293px;
                max-height: 293px;
                max-width: 265px;
                border-radius: 10px;
                // margin: 0 1em 1em 0;
                position: relative;
                box-sizing: border-box;

                &__Image {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }

            &__ImageUpload {
                width: 100px;
                height: 120px;
                border: 1px solid #000;
                border-radius: 10px;
                background: #f5f7fa;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                overflow: hidden;
            }
        }
    }
}