@import '../../styles/mixins.scss';
@import '../../styles/animations.scss';

.Dopplr_CameraActionBar {
    position: absolute;
    right: 1.5em;
    // right: 1.5em;
    bottom: 8em;
    transition: all 0.3s ease-in-out;
    @include disableUserSelect;
    &.hide {
        right: -5em;
    }

    // .selected {
    //     background: black;
    // }

    .mainIcon {
        width: 100%;
        height: 100%;
    }

    .cameraIcon {
        width: 100%;
        height: 100%;
    }

    &__ActionBar {
        display: flex;
        justify-content: space-between;
        position: absolute;
        // width: calc(100% + 1em);
        left: -1em;
        bottom: -8px;
        padding-left: 5em;
        height: 60px;
        // background: rgba(0, 0, 0, 0.6);
        box-sizing: border-box;
    }

    &__Action {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-left: 1px solid white;
        flex: 1;
        cursor: pointer;
    }

    &__Text {
        user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        font-size: 0.6em;
        padding-top: 0.3em;
        color: white;
    }

    &__ActionIcon {

        width: 30px;
        height: 30px;
        // border-radius: 13em;
        display: flex;
        justify-content: center;
        align-items: center;
        // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        margin: 0 1em;
        // background-color: white;
    }

    &__PrimaryAction {
        position: relative;
        z-index: 1;
        width: 35px;
        height: 35px;
        border-radius: 13em;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        cursor: pointer;
        box-shadow: rgb(0 0 0 / 50%) 1px 1px 1px 1px;
    }
}

// @media only screen and (max-width: 600px) {
//     .Dopplr_CameraActionBar {
//         &__PrimaryAction {
//             position: relative;
//             z-index: 1;
//             width: 40px;
//             height: 40px;
//             border-radius: 13em;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             background: white;
//             cursor: pointer;
//         }

//         &__ActionBar {
//             display: flex;
//             justify-content: space-between;
//             position: absolute;
//             left: -1em;
//             bottom: -7px;
//             padding-left: 5em;
//             height: 54px;
//             background: rgba(0, 0, 0, 0.6);
//             box-sizing: border-box;
//         }

//         &__ActionIcon {

//             width: 20px;
//             height: 20px;
//             // border-radius: 13em;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
//             margin: 0 1em;
//             // background-color: white;
//         }
//     }
// }

.Dopplr__CameraListWrapper {
    position: absolute;
    padding-bottom: 3em;
    left: 0;
    right: 0;
    background: white;
    padding: 0.5em;
    bottom: -11em;
    z-index: 100;
    padding-bottom: 1.5em;
    box-shadow: 0 -2px 2px 0 rgb(0, 0, 0, 0.25);
    transition: bottom 0.2s ease-in;
    -webkit-transition: bottom 0.2s ease-in;
    -moz-transition: bottom 0.2s ease-in;

    &.show {
        bottom: 0em;
    }

    &__Title {
        text-align: center;
        padding-bottom: 1em;
        font-size: 0.86em;
    }

    &__ToggleButton {
        position: absolute;
        top: -2.2em;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        background: white;
        width: 2em;
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 0 4px 10px rgb(0, 0, 0, 0.25);
        z-index: 1;
        cursor: pointer;

        img {
            width: 60%;
            transform: rotate(0deg);

            &.show {
                transform: rotate(180deg);
            }

            transition: transform 0.2s ease-in;
        }
    }

    &__List {
        display: flex;
        align-items: center;
        /* overflow-x: scroll; */
        justify-content: center;

        &__Skybox {
            &__Item {
                height: 3em;
                width: 3em;
                background: black;
                border-radius: 50%;
                overflow: hidden;
                margin: 0 1em;
                cursor: pointer;
                box-sizing: border-box;
                border: 1px solid #5c5c5c;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                &:hover {
                    transform: scale(1.1);
                }
                .text {
                    position: relative;
                    top: -0.1em;
                }

                &.selected {
                    border: 1px solid black;
                }


                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}