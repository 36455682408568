@import '../../styles/mixins.scss';

.Dopplr_CatalogList_Wrapper {
    max-height: 40%;
}

.Dopplr_CatalogList {
    display: grid;
    column-gap: 0.5em;
    position: relative;
    row-gap: 1em;
    // width: 100%;
    z-index: 1;
    overflow-x: hidden;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: max-content;
    justify-items: start;
    align-items: start;
    overflow-y: scroll;
    margin: 1em;
    // @include hideScrollBar;
    .ProductCard {
        // &:last-child {
        //     margin-bottom: 4em;
        // }
        cursor: pointer;

        // width: 20em;
        // height: 20em;
        cursor: pointer;
        display: flex;
        width: 100%;
        height: 100%;
        &__Image {
            // width: 100%;
            // height: 38em;
            // width: 8em;
            // height: 9em;
            border-radius: 10px;
            border: 1px solid #e3e3e3;

            @media screen and (max-width: 490px) {
                // width: 6em;
                // height: 7em;
                margin: 0.5em;
            }

            @media screen and (min-width: 540px) {
                // width: 12em;
                // height: 14em;
                margin: 0.5em;
            }

            &:hover {
                background-color: #f1f1f1;
            }

            &.selected {
                border: 2px solid #171717;
            }
        }

        // &__Description {
        //   padding: 1em 0.5em;
        //   color: white;
        //   &.selectedText {
        //     color: #f1df67;
        //   }
        // }

        img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
        }
    }
}