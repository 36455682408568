@import "../../styles/mixins.scss";
.avatarFormWrapper {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.AvatarForm {
  width: 100%;
  height: 100%;
  padding-bottom: 2em;
  overflow-y: scroll;
  background: #fff;
  z-index: 1;
  @include hideScrollBar;
  &__Title {
    // background: #3a3a3a;
    background: #f5f7fa;
    padding: 0.5em 1em;
    margin-bottom: 1em;
  }
  &__Actions {
    margin: 1em;
    &__Title {
      margin: 1em 0;
    }
  }
  &__BodyStatistics {
    margin: 1em 0;
    font-size: 0.86em;
    &__Button {
      background: #171717;
      padding: 1em 1em;
      color: #fff;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        width: 25px;
        height: 25px;
        margin-right: 1em;
      }
    }
  }
  &__InputWrapper {
    border-radius: 8px;
    margin: 1em;
    display: flex;
    height: 2.5em;
    // background: #f5f7fa;
    display: flex;
    align-items: center;
    // input {
    //   appearance: none;
    //   height: 2.5em;
    //   border-radius: 8px;
    //   padding-left: 1em;
    //   flex: 1 1;
    //   border: none;
    //   font-size: 0.8em;
    //   background: transparent;
    //   color: #000;
    //   &:focus {
    //     outline: none;
    //   }
    // }
  }
  &__RadioButton {
    display: flex;
    margin: 1em;
    &__Label {
      width: 30%;
      position: relative;
    }
    &__ButtonWrapper {
      display: flex;
      width: 70%;
      justify-content: space-between;
      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        position: absolute;
        top: 2px;
        left: 2px;
        opacity: 0;
        // left: -9999px;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
      }
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #636364;
        border-radius: 100%;
        background: #636364;
      }
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        content: "";
        width: 12px;
        height: 12px;
        background: #fff;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
      [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
  &__ImageUpload {
    width: 125px;
    height: 156px;
    border: 1px solid #000;
    border-radius: 10px;
    background: #f5f7fa;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 40px;
      height: 40px;
    }
    &:hover {
      border: 2px solid #171717;
    }
  }
  .uploadedImageContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 1em;
    overflow: scroll;
    max-height: 50vh;
    @include hide-scrollbar;

    .uploadedImage {
      width: 125px;
      height: 156px;
      border: 1px solid #dedede;
      border-radius: 10px;
      min-width: 125px;
      min-height: 156px;
      max-height: 156px;
      max-width: 125px;
      border-radius: 10px;
      margin: 0 1em 1em 0;
      position: relative;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    .removeIcon {
      position: absolute;
      top: -15px;
      right: -0.4em;
      width: 1.5em;
      height: 1.5em;
      color: white;
      padding: 0.5em;
      background: #636364;
      .CloseButton__Icon {
        font-size: 0.7em;
      }
    }
  }

  .SwitchUnitWrapper {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 1em 1em 1em;
    .SwitchUnit {
      text-decoration: underline;
    }
  }

  .BodyMeasurements__Error {
    color: red;
    font-size: 0.8em;
    margin: 0 1em;
    height: 1em;
  }

  .BodyMeasurements__Form {
    margin: 0 1em;
    &__MeasurementInput {
      width: 50px;
      background: #636364;
      appearance: none;
    }
    input {
      &:focus {
        outline: none;
      }
    }
    &__MeasurementRange {
      width: 100%;
    }
    &__Input {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
