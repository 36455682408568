.Dopplr__BubbleLoader {
    padding: 0 1em;
    .Bubble {
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        display: inline-block;
        animation: pulse 1.1s infinite ease-in-out;
        animation-fill-mode: both;
    }

    &__Bubble1 {
        animation-delay: 0s !important;
    }

    &__Bubble2 {
        animation-delay: 0.15s !important;
    }

    &__Bubble3 {
        animation-delay: 0.3s !important;
    }
}



@keyframes pulse {

    0%,
    80%,
    100% {
        transform: scale(0.6);
        opacity: 0.2;
    }

    40% {
        transform: scale(1.0);
        opacity: 1;
    }
}