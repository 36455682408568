.Dopplr__ShareAvatar {
    &__Content {
        margin: 1em;
        display: flex;
        border-radius: 1em;
        justify-content: center;
        align-items: center;
    }

    &__ImageWrapper {
        height: 50vh;
        box-shadow: 0px 1px 2px 1px rgb(0, 0, 0, 0.5);
        border-radius: 0.5em;
        overflow: hidden;
        img {
            height: 100%;
            object-fit: cover;
        }
    }

    &__Actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}