.Dopplr__HeatmapLegendContainer {
    height: 3.5em;

    &__InformationOverlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 2;
    }

    &__InformationIcon {
        width: 1.5em;
        height: 1.5em;
        position: absolute;
        right: 1em;
        font-weight: 600;
        top: -2em;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #888888;
        border-radius: 2em;
        background: white;
        cursor: pointer;
        z-index: 2;

        &.selected {
            background: var(--primaryColor);
            color: white;
        }
    }
}

.Dopplr__HeatmapLegend {
    position: absolute;
    right: 1em;
    left: 1em;
    bottom: 2.5em;
    color: black;
    margin: auto;
    font-size: 0.7em;
    z-index: 2;

    &__Image {
        width: 100%;
        height: 15px;
        display: flex;
        box-sizing: border-box;
        &__Slim {
            background-image: linear-gradient(to right, #F0B901, #EAFE01, #A4FF01);
            border-right: 2px solid white;
            box-sizing: border-box;
            height: 15px;
            position: relative;
        }
        &__Text {
            position: absolute;
            top: 1.8em;
            left: 50%;
            transform: translateX(-50%);
        }

        &__Regular {
            position: relative;
            border-right: 2px solid white;
            background-image: linear-gradient(to right, #A4FF01, #08FF01, #55B0AF);
            box-sizing: border-box;
            height: 15px;
        }

        &__Loose {
            position: relative;
            background-image: linear-gradient(to right, #2CAF73, #619ACF,  #0B43F5);
            box-sizing: border-box;
            height: 15px;
        }
        &__OutOfRangeLeft {
            width: 4em;
            height: 15px;
            background-image: linear-gradient(to right, #f20909, #F0B901);
        }
        &__OutOfRangeRight {
            width: 4em;
            height: 15px;
            background-image: linear-gradient(to right, #0B43F5, #f20909);
        }
    }

    // transform: translateX(1000%);
    @keyframes legendAppearFromRight {
        0% {
            transform: translateX(1000%);
        }

        95% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(0%);
        }
    }

    @keyframes legendDisappearToRight {
        0% {
            transform: translateX(0%);
        }

        95% {
            transform: translateX(1000%);
        }

        100% {
            transform: translateX(1000%);
            display: none;
        }
    }

    &.show {
        animation: legendAppearFromRight 0.2s linear forwards;
    }

    &.hide {
        animation: legendDisappearToRight 0.2s linear forwards;
    }

    img {
        width: 100%;
    }

    &__IndicatorTop {
        position: absolute;
        width: 30px;
        height: 30px;
        z-index: 1;
        background: #EEEEEE;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -1em;
        cursor: pointer;
        transform: translateX(-50%);
        color: var(--primaryColor);
        border: 0.5px solid #888888;

        &.selected {
            background: var(--primaryColor);
            color: white;
        }

        // &::before {
        //     content: "";
        //     position: absolute;
        //     bottom: -11px;
        //     border-right: 5px solid transparent;
        //     border-top: 5px solid var(--primaryColor);
        //     border-left: 5px solid transparent;
        //     border-bottom: 5px solid transparent;
        // }
    }

    &__IndicatorBottom {
        color: white;
        position: absolute;
        right: 10px;
        width: 30px;
    }

    &__Loose {
        position: absolute;
        left: 0;
        bottom: -1.5em;
        width: 3em;
    }

    &__Comfortable {
        position: absolute;
        left: 50%;
        bottom: -1.2em;
        transform: translateX(-50%);
    }

    &__Tight {
        position: absolute;
        right: 0;
        bottom: -1.2em;
    }
}