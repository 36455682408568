.BodyStats {
  padding-top: 1em;
  &__Title {
    background: #3a3a3a;
    padding: 0.5em 1em;
  }
  &__Section {
    &__Title {
      padding: 1em;
      span {
        font-size: 0.8em;
        color: #3a3a3a;
      }
    }
  }
  &__Description {
    padding-bottom: 2em;
    &__Label {
      color: #3a3a3a;
      width: 30%;
    }
    &__Value {
    }
    &__Row {
      display: flex;
      align-items: center;
      margin: 0.5em 1em;
      font-size: 0.8em;
    }
  }
}
