.Dopplr_Summary {
    font-size: 1.2em;
    padding: 0.5em 1em 1em 1em;
    &__SummaryText {
        // padding: 2em;
    }
    &__SummaryValue {
        color: var(--primaryColor);
        font-size: 2em;
        font-weight: 600;
    }
}