@mixin appearZoomIn($duration) {
  animation: #{$duration} linear zoomingAppearance;
  -webkit-animation: #{$duration} linear zoomingAppearance;

  @keyframes zoomingAppearance {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @-webkit-keyframes zoomingAppearance {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}

@mixin appearZoomOut($duration) {
  animation: #{$duration} linear unzoomingAppearance;
  -webkit-animation: #{$duration} linear unzoomingAppearance;

  @keyframes unzoomingAppearance {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  @-webkit-keyframes unzoomingAppearance {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
}

@mixin appearBottom($duration) {
  animation: #{$duration} linear appearFromBottom;
  -webkit-animation: #{$duration} linear appearFromBottom;

  @keyframes appearFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    90% {
      opacity: 1;
    }
    100% {
      transform: translateY(4%);
      opacity: 1;
    }
  }
  @-webkit-keyframes appearFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
}

@mixin appearScaleY($duration) {
  animation: #{$duration} ease-in-out appearScaleInY;
  -webkit-animation: #{$duration} ease-in-out appearScaleInY;
  transform-origin: top;

  @keyframes appearScaleInY {
    0% {
      transform: scaleY(0);
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes appearScaleInY {
    0% {
      transform: scaleY(0);
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }
}

@mixin appearBlur($duration) {
  animation: #{$duration} ease-in blurIntoView;
  -webkit-animation: #{$duration} ease-in blurIntoView;

  @keyframes blurIntoView {
    0% {
      filter: blur(5em);
    }
    100% {
      filter: blur(0);
    }
  }
  @-webkit-keyframes blurIntoView {
    0% {
      filter: blur(5em);
    }
    100% {
      filter: blur(0);
    }
  }
}

@mixin appearBlurZoomOut($duration) {
  animation: #{$duration} ease-in blurAndZoomOut;
  -webkit-animation: #{$duration} ease-in blurAndZoomOut;

  @keyframes blurAndZoomOut {
    0% {
      filter: blur(3px);
      transform: scale(1.2);
    }
    10% {
      filter: blur(0em);
      transform: scale(1.2);
    }
    100% {
      filter: blur(0);
      transform: scale(1);
    }
  }
  @-webkit-keyframes blurAndZoomOut {
    0% {
      filter: blur(3px);
      transform: scale(1.2);
    }
    10% {
      filter: blur(0em);
      transform: scale(1.2);
    }
    100% {
      filter: blur(0);
      transform: scale(1);
    }
  }
}

@mixin appearOpacityZoomout($duration) {
  animation: #{$duration} ease-in appearViaOpacity;
  -webkit-animation: #{$duration} ease-in appearViaOpacity;
  transform-origin: center;

  @keyframes appearViaOpacity {
    0% {
      transform: scale(1.2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes appearViaOpacity {
    0% {
      transform: scale(1.2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}

@keyframes slidedownWithWiggle {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideupWithWiggle {
  0% {
    transform: translateY(100%);
  }
  70% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(0.3%);
  }
  100% {
    transform: translateY(0%);
  }
}

@mixin threeDotsLoader($dotColor) {
  $animation-name: unique-id() !global;

  animation: #{$animation-name} infinite linear 1s;
  -webkit-animation: #{$animation-name} infinite linear 1s;

  @keyframes #{$animation-name} {
    0% {
      box-shadow: -1em 0px 0px 1px $dotColor, 0px 0px 0px 1px $dotColor, 1em 0px 0px 1px $dotColor;
    }
    25% {
      box-shadow: -1em 0px 0px 4px $dotColor, 0px 0px 0px 1px $dotColor, 1em 0px 0px 1px $dotColor;
    }
    50% {
      box-shadow: -1em 0px 0px 1px $dotColor, 0px 0px 0px 4px $dotColor, 1em 0px 0px 1px $dotColor;
    }
    75% {
      box-shadow: -1em 0px 0px 1px $dotColor, 0px 0px 0px 1px $dotColor, 1em 0px 0px 4px $dotColor;
    }
    100% {
      box-shadow: -1em 0px 0px 1px $dotColor, 0px 0px 0px 1px $dotColor, 1em 0px 0px 1px $dotColor;
    }
  }
  @-webkit-keyframes #{$animation-name} {
    0% {
      box-shadow: -1em 0px 0px 1px $dotColor, 0px 0px 0px 1px $dotColor, 1em 0px 0px 1px $dotColor;
    }
    25% {
      box-shadow: -1em 0px 0px 4px $dotColor, 0px 0px 0px 1px $dotColor, 1em 0px 0px 1px $dotColor;
    }
    50% {
      box-shadow: -1em 0px 0px 1px $dotColor, 0px 0px 0px 4px $dotColor, 1em 0px 0px 1px $dotColor;
    }
    75% {
      box-shadow: -1em 0px 0px 1px $dotColor, 0px 0px 0px 1px $dotColor, 1em 0px 0px 4px $dotColor;
    }
    100% {
      box-shadow: -1em 0px 0px 1px $dotColor, 0px 0px 0px 1px $dotColor, 1em 0px 0px 1px $dotColor;
    }
  }
}

@mixin ring-animation($ringColor, $duration) {
  $animation-name: unique-id() !global;

  animation: #{$animation-name} infinite linear $duration;
  -webkit-animation: #{$animation-name} infinite linear $duration;

  @keyframes #{$animation-name} {
    0% {
      transform: scale(1);
      border: 1px solid $ringColor;
    }
    15% {
      transform: scale(1.1);
      border: 1.5px solid $ringColor;
    }
    30% {
      transform: scale(1.1);
      border: 2px solid $ringColor;
    }
    45% {
      transform: scale(1.1);
      border: 2px solid $ringColor;
    }
    60% {
      transform: scale(1);
      border: 1px solid $ringColor;
    }
  }
  @-webkit-keyframes #{$animation-name} {
    0% {
      transform: scale(1);
      border: 1px solid $ringColor;
    }
    15% {
      transform: scale(1.1);
      border: 1.5px solid $ringColor;
    }
    30% {
      transform: scale(1.1);
      border: 2px solid $ringColor;
    }
    45% {
      transform: scale(1.1);
      border: 2px solid $ringColor;
    }
    60% {
      transform: scale(1);
      border: 1px solid $ringColor;
    }
  }
}

@keyframes circle-animation {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(0.8);
  }
  45% {
    transform: scale(0.8);
  }
  60% {
    transform: scale(1);
  }
}

@keyframes wave-animation {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.2);
  }
  30% {
    transform: scale(1);
  }
  45% {
    transform: scale(0.8);
  }
  60% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  90% {
    transform: scale(1);
  }
}

@keyframes wave-ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(1.75, 1.75, 1);
  }
}

@mixin wave-rings($duration, $delay, $borderWidth: 3px) {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -$borderWidth;
  left: -$borderWidth;
  right: 0;
  bottom: 0;
  content: '';
  height: 100%;
  width: 100%;
  border: $borderWidth solid lighten(#b84082, 25);
  border-radius: 100%;
  animation-name: wave-ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: 0;
}

@keyframes horizontal-scrolling {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-30%);
  }
  40% {
    transform: translateX(-30%);
  }
  50% {
    transform: translateX(-50%);
  }
  65% {
    transform: translateX(-50%);
  }
  75% {
    transform: translateX(-30%);
  }
  90% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes appearFromLeft {
  0% {
    transform: translateX(-100%);
  }
  95% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(1%);
  }
}

@keyframes appearFromRight {
  0% {
    transform: translateX(100%);
  }
  95% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(1%);
  }
}

@keyframes fast-scrolling {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-50%);
  }
}
