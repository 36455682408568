@import 'styles/mixins.scss';

.Button {
  min-width: 50%;
  width: 100%;
  //   max-width: 20em;
  padding: 0.5em 1em;
  position: relative;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 1em;
  font-weight: 500;
  color: white;

  cursor: pointer;
  border: none;
  border-radius: 3em;
  box-sizing: border-box;
  // box-shadow: 1px 1px 0.75em 1px rgba(0, 0, 0, 0.2);

  outline: none;
  &:active,
  &:hover,
  &:focus {
    outline: none;
  }
  will-change: background-color;
  transition: background-color 0.1s;

  &__Container {
    // width: calc(100% - 1em);
    width: 100%;
    padding: 0.5em;
    // margin-bottom: 0.5em;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__LoadingOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(255, 255, 255, 0.4);

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .circle-loader {
      height: 70%;
      width: auto;
      max-width: 2em;
    }
  }
  &.gray-blue {
    background-color: rgba(248, 248, 248, 1);
    color: #1e95b6;
    // @include ripple(rgba(248, 248, 248, 1), #{lighten(rgba(248, 248, 248, 1), 10%)});
  }
  &.yellow-black {
    background: #171717;
    color: #fff;
    border: 1px solid #171717;
    // @include ripple(rgba(255, 116, 88, 1), #{lighten(rgba(255, 116, 88, 1), 10%)});
  }
  &.grey-white {
    color: #878787;
    background-color: #202020;
    border: 1px solid #878787;
    // @include ripple(#fff, #{lighten(#fff, 10%)});
  }
  &.black-white {
    background-color: var(--primaryColor);
    border: 1px solid #fff;
    color: white;
  }
  &.white-black {
    background-color: #EEEEEE;
    border: 1px solid #6d6d6d;
    color: var(--primaryColor);
  }
  &.white-black-pure {
    background-color: white;
    border: 1px solid #000;
    color: #000;
  }
  &.white-orange {
    color: rgba(255, 94, 72, 1);
    background: white;
    border: 1px solid rgba(255, 94, 72, 1);
  }
  &.blue-white {
    background-color: #1e95b6;
    color: white;
    // @include ripple(#1e95b6, #{lighten(#1e95b6, 10%)});
  }
  &.white-black {
    background-color: #EEEEEE;
    color: #5C5C5C;
  }
  &.gray {
    background: linear-gradient(180deg, #aaa 5%, #ccc);
    &:active {
      background: lighten(#aaa, 10%);
    }
    cursor: default;
    // @include ripple(#ccc, #aaa);
  }
  &.google {
    background-color: #5184EC;
    border: 1px solid #5184EC;
    color: #fff;
  }
}
