@import "../../styles/mixins.scss";

.Dopplr_LoginWrapper {
  width: 100%;
  height: 100%;
  background: #181923;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoginLayout {
  box-shadow: none;

  .LoginContainer {
    height: 100%;
    padding-bottom: 2em;
    overflow-y: scroll;
    position: relative;
    top: 1px;
    padding: 1em;
    @include hideScrollBar;

    &__Loader {
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
    }

    .LoginForm {
      // color: #fff;
      margin: 1em;
      background: #f0f0f0;
      padding: 1em;
      border-radius: 1em;

      &__SignupOther {
        color: #484848;
      }

      &__Title {
        text-align: center;
        font-weight: 600;
      }
    }

    .DooplrIconContainer {
      height: 4em;
      display: flex;
      justify-content: center;

      img {
        height: 100%;
      }
    }

    &__Title {
      color: #878787;
    }

    &__Text {
      margin: 1em 0;
      display: flex;
      justify-content: center;
    }

    &__BottomText {
      text-align: center;
    }

    &__LoginOptions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.5em 0;
    }

    &__InputWrapper {
      margin: 0.5em 0 1em 0;

      &.NoMargin {
        margin: 0;
      }
    }

    &__Terms {
      display: flex;
      margin-bottom: 1em;

      span {
        text-decoration: underline;
      }
    }

    &__ForgotPasswordWrapper {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 1em;
      height: 1.2em;

      &__ForgotPassword {
        cursor: pointer;
      }
    }

    &__Action {
      .Button__Container {
        padding: 0;
      }
    }
  }
}